const debounce = (
  fn: (...args: any[]) => any,
  delay: number
): ((...args: any[]) => any) => {
  let timeoutId: ReturnType<typeof setTimeout>;
  return function (...args: any) {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      fn(...args);
    }, delay);
  };
};

export default debounce;
