const vClickOutsideIgnoreDrag = {
  beforeMount(el: any, binding: any): void {
    el.onMouseUp = function (event: MouseEvent) {
      if (!el.contains(event.target)) {
        binding.value(event);
      }
    };
    setTimeout(() => {
      document.body.addEventListener("mousedown", el.onMouseDown);
      document.body.addEventListener("mouseup", el.onMouseUp);
    });
  },
  unmounted(el: any): void {
    document.body.removeEventListener("mousedown", el.onMouseDown);
    document.body.removeEventListener("mouseup", el.onMouseUp);
  },
};

export default vClickOutsideIgnoreDrag;
