import { AxiosResponse } from "axios";
import ApiService from "@/services";
import { AddPartFeedbackPayload } from "@/types/api/AddPartFeedbackPayload";

export default class PartFeedbackService {
  static getPartFeedbacks(rfqPartId: string): Promise<AxiosResponse> {
    return ApiService.get("/v1/part-feedback/", { params: { rfqPartId } });
  }

  static getPartFeedbacksForOrder(partId: string): Promise<AxiosResponse> {
    return ApiService.get("/v1/part-feedback/track-orders", {
      params: { partId },
    });
  }

  static addPartFeedback(
    params: AddPartFeedbackPayload
  ): Promise<AxiosResponse> {
    return ApiService.post("/v1/part-feedback/", params);
  }

  static editPartFeedbackSubject(
    rfqPartId: string,
    feedbackId: string,
    subject: string
  ): Promise<AxiosResponse> {
    return ApiService.patch(`/v1/part-feedback/${rfqPartId}/${feedbackId}`, {
      subject,
    });
  }

  static resolvePartFeedback(
    feedbackId: string,
    rfqPartId: string,
    rfqId: string
  ): Promise<AxiosResponse> {
    return ApiService.patch(`/v1/part-feedback/${feedbackId}/resolve`, {
      rfqPartId,
      rfqId,
    });
  }

  static acceptPartFeedback(
    feedbackId: string,
    rfqPartId: string,
    rfqId: string
  ): Promise<AxiosResponse> {
    return ApiService.patch(`/v1/part-feedback/${feedbackId}/accept`, {
      rfqPartId,
      rfqId,
    });
  }

  static reopenPartFeedback(
    feedbackId: string,
    rfqPartId: string,
    rfqId: string
  ): Promise<AxiosResponse> {
    return ApiService.patch(`/v1/part-feedback/${feedbackId}/reopen`, {
      rfqPartId,
      rfqId,
    });
  }

  static deletePartFeedback(id: string): Promise<AxiosResponse> {
    return ApiService.delete(`/v1/part-feedback/${id}`);
  }

  static addPartFeedbackMessage(payload: {
    threadId: string;
    body: string;
    rfqId: string;
    rfqPartId: string;
  }): Promise<AxiosResponse> {
    return ApiService.post("/v1/part-feedback/message", payload);
  }

  static editPartFeedbackMessage(
    id: string,
    message: string
  ): Promise<AxiosResponse> {
    return ApiService.patch(`/v1/part-feedback/message/${id}`, { message });
  }

  static deletePartFeedbackMessage(id: string): Promise<AxiosResponse> {
    return ApiService.delete(`/v1/part-feedback/message/${id}`);
  }
}
